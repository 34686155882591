import React from "react"
import { Link, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"

// Compopnents
import Layout from "../components/layout"
import SEO from "../components/seo"
import TeamMembers from "../components/teamMembers"
import { Container, Row, Col } from "react-bootstrap"

// Styles
import teamStyles from "../pages/team.module.scss"
import indexStyles from "../pages/index.module.scss"

export const query = graphql`
    query teamTemplate($url: String!) {
        heroBackground: file(relativePath: { regex: "/rainbow/" }) {
            childImageSharp {
                fluid
                {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        logo: file(relativePath: { regex: "/logo_transparent/" }) {
            childImageSharp {
                fluid
                {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        strapiTeam{
            heroSubtitle
            heroTitle
            intro
            introTitle
        }

        strapiEmployees( urlSlug: { eq: $url } ) {
            name
            email
            position
            subtitle
            tel
            telInternal
            title
            description
            urlSlug
            profilePicture {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }

    }
`

const TeamPage = ({ data }) => {

    const teamMember = data.strapiEmployees

    return (
        <Layout>
            <SEO title="Team" />
            {/* <BackgroundImage
                className={`backgroundImage`}
                fluid={data.heroBackground.childImageSharp.fluid}
            >
                <Container className={`hero mainMid`} />
            </BackgroundImage> */}

            {/* <Container className={`heroTextBox`}> */}
            {/* <h1 className={`smallH1`}>{data.strapiTeam.heroTitle}</h1>
                <span className={`headline`}>{data.strapiTeam.heroSubtitle}</span> */}

            {/* </Container> */}

            <Container fluid className={` ${teamStyles.teamMemberBackground}`}>
                <Container id="description" className={`pt-5 ${teamStyles.descriptionBox}`}>
                    <Row className={`justify-content-center`}>
                        <div className={`${teamStyles.teamMember}`}>
                            <Img className={teamStyles.teamMemberImgBig}
                                fluid={teamMember.profilePicture === null ? data.logo.childImageSharp.fluid : teamMember.profilePicture.childImageSharp.fluid}
                            />
                        </div>
                    </Row>
                    {/* Vorname Nachname */}
                    <Row className={`mt-4 text-center`}>
                        <Col>
                            <h2 className={`mb-0`}>{teamMember.name}</h2>
                        </Col>
                    </Row>

                    {/* Titel */}
                    <Row className={`${teamStyles.title} justify-content-center mb-2`}>
                        {teamMember.subtitle}
                    </Row>


                    {/* E-Mail */}
                    <Row className={`my-2 justify-content-center`}>
                            <a href={`mailto:${teamMember.email}`} className={teamStyles.mail}>{teamMember.email}</a>
                            <span className={`mx-3 ${teamStyles.mail}`}>•</span>
                            <a href={`tel:${teamMember.tel}`} className={teamStyles.tel}>{teamMember.tel}</a>
                    </Row>

                    {/* Content */}
                    <Row>
                        <Col className={`my-auto py-4 ${teamStyles.description}`}>
                            <ReactMarkdown source={teamMember.description} escapeHtml={false} />
                        </Col>
                    </Row>
                </Container>
            </Container>


            {/* Section 2 */}
            <Container id="title" className={`mainMid my-5`}>
                <Row>
                    <Col className={`text-center`}>
                        <h2>
                            {data.strapiTeam.heroTitle}
                        </h2>
                    </Col>
                </Row>
                <Row>
                    <Col className={`text-center`}>

                        <ReactMarkdown source={data.strapiTeam.intro} escapeHtml={false} />
                    </Col>
                </Row>

                <TeamMembers useOpacity={true} justifyLeft={false} />

            </Container>
        </Layout>
    )
}

export default TeamPage
